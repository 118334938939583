@import '../../_styles/colors';

.markdown {
  overflow: auto;

  p:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  a {
    color: $primary-color;
    font-weight: bold;
  }
}
