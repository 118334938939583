.users {
  .table th {
    &:nth-child(1) {
      width: 30rem;
    }
    &:nth-child(2),
    &:nth-child(3) {
      width: 25rem;
    }
    &:nth-child(4),
    &:nth-child(5) {
      width: 20rem;
    }
    &:nth-child(6) {
      width: 15rem;
    }
  }
}
