@import '../../_styles/colors';

.section-status {
  .status {
    font-weight: bold;
    &.ACTIVE {
      color: $green;
    }
    &.INACTIVE {
      color: $red;
    }
    &.REGISTERING {
      color: $orange;
    }
  }
}
