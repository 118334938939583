@import '../../_styles/colors';

.permissions {
  h3 {
    margin-bottom: 1rem;
  }

  > .input-wrapper + .input-wrapper {
    margin-top: 2rem;
  }
}
